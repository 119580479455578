import { PolicyBillingOption } from "types"
import { DataObject } from "./data-object"
import { BillingType } from "./invoice"
import { EndorsementBillingSchedule, PolicyBillingInstallment, PolicyBillingSchedule } from "./policy"

export enum ClientFinixStatus {
  pending='pending',
  approved='approved',
  suspended='suspended',
}

export type ClientData = {
  //reply_to?: string
  accounts_payable_email?: string
}

export type ClientWireInstructions = DataObject & {
  bank_name: string
  bank_address: string
  account_holder_name: string
  account_holder_address: string
  swift: string
  routing_number: string
  account_number: string
}

export type ClientCheckInstructions = DataObject & {
  payable_to: string
  mailing_address: string
}

export type ClientFeatures = DataObject & {
  is_demo?: boolean
  accepts_ach?: boolean
  accepts_cc?: boolean
  accepts_wire?: boolean
  accepts_check?: boolean
}

export type ClientBillingSettings = DataObject & {  
  billing_type: BillingType
  enabled: boolean
  
  schedule_type?: PolicyBillingSchedule
  installment_type?: PolicyBillingInstallment
  
  installment_type_options?: PolicyBillingOption
  installment_fee?: number
  installment_type_financing?: string

  downpayment_schedule_type?: PolicyBillingSchedule

  endorsement_schedule_type?: EndorsementBillingSchedule
  endorsement_installment_type?: PolicyBillingInstallment

  statement_bill_day?: number
  statement_enabled?: boolean
  statement_due_in_days?: number
  due_in_days?: number

  downpayment_pct?: number
  downpayment_includes_taxes?: boolean
  downpayment_includes_fees?: boolean
  
}


export type ClientBranding = DataObject & {
  logo_file_name?: string
  primary_color?: string
  secondary_color?: string
}

export type ClientEmailSettings = DataObject & {
  invoice_reply_to_email?: string
  invoice_send_from_email?: string
  accounts_payable_email?: string
  internal_invoice_email?: string
  client_sendgrid_api_key?: string
}


export type ClientFinixSettings = DataObject & {
  is_finix_allowed: boolean,
  is_finix_enabled: boolean,
  finix_status: string,
  finix_merchant_id: string,
  finix_identity_id: string,
  processing_rate_card_pct: number,
  processing_rate_card_amt: number,
  processing_rate_ach_amt: number
  ach_limit_amt: number
}

export type Client = DataObject & {
  name: string 
  contact_email: string
  contact_phone: string
  license_number: string
  license_state: string
  domain: string
  features?: ClientFeatures
  billing_settings?: Array<ClientBillingSettings>
  branding?: ClientBranding
  wire_instructions?: ClientWireInstructions
  check_instructions?: ClientCheckInstructions
  finix_status?: ClientFinixStatus
  finix_payment_plan_id?: string
  finix_settings?: ClientFinixSettings
  email_settings?: ClientEmailSettings
  finix_merchant_id: string
  data: ClientData,
}

export type ClientSettings = {
  
}