import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';


import type { AppProps } from 'next/app'
import Head from 'next/head';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';


import UserContext, { UserContextType } from 'context/UserContext';
//import LocaleContext, { LocaleContextType } from 'context/LocaleContext';


import theme from 'theme';
import createEmotionCache from 'theme/createEmotionCache';
const clientSideEmotionCache = createEmotionCache();


import { Maybe, User, UserType } from 'types';
interface FFAppProps extends AppProps {
  emotionCache?: EmotionCache;
}


import '../styles/globals.css'
import { identify, logout as backendLogout } from 'requests/auth';
import ErrorBoundary from 'element/ErrorBoundary';
import { Loading } from 'element';
import { clientGet } from 'requests/client';

const USER_ID_KEY = "FF_USER_ID"


function MyApp({ Component, emotionCache = clientSideEmotionCache, pageProps }: FFAppProps) {

  const [user, setUser] = useState<Maybe<User>>(undefined);

  // let storedUserId: Maybe<string> = null;
  // if (typeof(window)!=='undefined') {
  //   const storedUserIdString: Maybe<string> = window.localStorage.getItem(USER_ID_KEY);
  //   if (storedUserIdString) {
  //     storedUserId = storedUserIdString;
  //   }
  // }

  // const setUserFromId = async (newUserId: Maybe<string>) => {
  //   if (newUserId) {
  //     // It's always self?
  //     try {
  //       const newUser = (await identify()) as User;
  //       setUser(newUser);
  //     } catch (err) {
  //       // No current user
  //       setUser(null);
  //       console.error(err);        
  //     }
  //   } else {
  //     setUser(null);
  //   }
  // }
  const loadUserFromSession = async () => {
    try {
      const userResp = await identify();
      if (userResp.user_type === UserType.client && userResp.client_id) {
        const clientResp = await clientGet({id: userResp.client_id})
        userResp.client = clientResp;
      }
      setUser(userResp);
    } catch (err) {
      // No current user
      setUser(null);
      console.error(err);
    }
  }

  useEffect(()=>{
    
    // TODO: store a user-updated-at data so even if user is present
    // in the state tree, check they're still logged in if it's been a minute.
    // e.g. if (!user || userNeedsRefresh)
    if (!user) {
      loadUserFromSession();
    }
  }, [])

  // useEffect(()=>{
  //   if (storedUserId && user?.id != storedUserId) {
  //     console.log("loading full user data")
  //     setUserFromId(storedUserId);
  //   }
  // }, [storedUserId])

  const baseUserContext : UserContextType = {
    user: user as Maybe<User>,
    //setUserId: setUserFromId,
    loadUserFromSession,
    logout: () => {
      backendLogout();
      setUser(null);
      //setUserFromId(null);
    }
  } 

  const isLoading = typeof(user) === "undefined"
  return <ErrorBoundary>
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>        
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns}>        
        {/* <LocaleContext.Provider value={baseLocaleContext}> */}
          <UserContext.Provider value={baseUserContext}>
            <Head>
              <title>Functional Finance</title>
              <meta name="description" content="Functional Finance" />
              <link rel="icon" href="/favicon.ico" />
              {/* <script type="text/javascript" src="https://forms.finixpymnts.com/finix.js"></script>*/}
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="crossOrigin" />
              <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap" rel="stylesheet" />
              <script type="text/javascript" src="/sardine-dev-init.js"></script>
            </Head>
            {isLoading ? <Loading /> : <Component {...pageProps} />}
          </UserContext.Provider>
        {/* </LocaleContext.Provider> */}
        </LocalizationProvider>
      </ThemeProvider>
    </CacheProvider>
  </ErrorBoundary>
}
export default MyApp
