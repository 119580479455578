import { DataObject } from "./data-object";

export enum PolicyItemCategory {
  premium="premium", 
  tax="tax", 
  fee="fee",
  other="other",
  reserved="reserved" 
}

export enum PolicyItemSubCategory {
  gross_written_premium="gross_written_premium",
  agency_commission="agency_commission",
  fidiciuary_premium_collected="fiduciary_premium",
  processing_fee="processing_fee"
}

export type PolicyItemType = DataObject & {
  client_id: string
  item_category: PolicyItemCategory
  name: string
  description: string
  
  default_amount: number
  default_description: string
  
  sort: number

  slug: string
  accounting_account_identifier: string
  accounting_account_name: string
  item_subcategory: PolicyItemSubCategory
  is_hidden: boolean
  is_nonbillable: boolean
}

export type PolicyItem = DataObject & {
  policy_item_type_id: string
  insurance_policy_id?: string
  insurance_endorsement_id?: string
  amount: number
  description: string
  policy_item_type?:	PolicyItemType
  is_hidden: boolean
  is_nonbillable: boolean
}