import { Carrier, CoverageType, Product } from "types"
import { Cart } from "./cart"
import { Client } from "./client"
import { DataObject } from "./data-object"
import { EndInsured } from "./end-insured"
import { BillingType, InvoiceItem } from "./invoice"
import { PolicyItem } from "./policy_item"



export enum PolicyStatus {
  quote="quote", 
  bind="bind", 
  policy="policy", 
  canceled="canceled"
}

export enum PolicyBillingOption {
  pay_in_full = "pay_in_full",
  installments = "installments",
  choice = "choice"
}

export enum PolicyBillingSchedule {
  policy_date = "policy_date",
  statement = "statement"
}

export enum EndorsementBillingSchedule {
  endorsement_date = 'endorsement_date',
  policy_date = "policy_date",
  statement = "statement"
}

export enum  PolicyBillingInstallment {
  pay_in_full = "pay_in_full",
  down_payment_quarterly = "down_payment_quarterly",
  down_payment_monthly = "down_payment_monthly",
  quarterly = "quarterly",
  monthly = "monthly",
}

export enum EndorsementBillingInstallment {
  pay_in_full = "pay_in_full",
  quarterly = "quarterly",
  monthly = "monthly",
}

export type PolicyBillingAmountDate = {
  date: string
  premium_amt: number
  taxes_amt: number
  fees_amt: number
  item?: Policy | Endorsement
}


export type Policy = DataObject & { 
  client_id?: string
  
  cart_id?: string
  cart?: Cart

  insured_id?: string
  insured?: EndInsured
  insured_name?: string

  agency_client_id?: string
  agency?: Client
  agency_name?: string

  billing_type?: BillingType

  insurance_product_id?: string
  product?: Product
  
  insurance_coverage_type_id?:	string
  insurance_coverage_type_name?: string
  coverage_type?: CoverageType
  
  insurance_carrier_id?:	string
  insurance_carrier_name?: string
  carrier?: Carrier
  
  status?:	PolicyStatus
  quote_num?: string
  binder_num?:	string
  policy_num?:	string
  description?: string

  
  
  balance_amt?: number
  balance_due_date: string
  total_payable_amt: number

  transaction_date: string  
  start_date: string
  end_date: string

  premium_amt?: number
  tria_premium_amt?: number
  taxes_amt?: number
  policy_fee_amt: number
  service_fee_amt: number
  
  client_commission_pct?: number
  agency_partner_commission_pct?: number
  // commission_pct?: number
  // mga_commission_pct?: number
  
  policy_mep_pct?: number
  is_policy_short_rate?: boolean
  is_policy_auditable?: boolean
  is_policy_filings?: boolean

  invoice_items?: Array<InvoiceItem>

  premium_billable_amt?: number

  schedule_type?: PolicyBillingSchedule
  installment_type?: PolicyBillingInstallment
  premium_downpayment_amt?: number
  taxes_downpayment_amt?: number
  fees_downpayment_amt?: number
  downpayment_pct?: number
  downpayment_includes_taxes?: boolean
  downpayment_includes_fees?: boolean

  
  downpayment_schedule_type?: PolicyBillingSchedule
  endorsement_schedule_type?: EndorsementBillingSchedule
  endorsement_installment_type?: PolicyBillingInstallment

  endorsements: Array<Endorsement>
  
  calculated_billing_schedule: Array<PolicyBillingAmountDate>
  remaining_billing_schedule: Array<PolicyBillingAmountDate>  
  upcoming_invoice_details: PolicyBillingAmountDate

  policy_items?: Array<PolicyItem>

  invoice_exclude_taxes: boolean
  invoice_exclude_fees: boolean
}

export type Endorsement = DataObject & {
  insurance_policy_id: string

  name?: string
  description?: string

  status?: PolicyStatus
  quote_num?: string
  binder_num?: string
  policy_num?: string
  policy?: Policy
  premium_amt?: number
  premium_billable_amt?: number
  taxes_amt?: number
  fees_amt?: number

  start_date: string
  end_date: string
  transaction_date: string
  client_id: string

  schedule_type?: EndorsementBillingSchedule
  installment_type?: PolicyBillingInstallment
  is_policy_adjustment?: boolean
  
  invoice_items?: Array<InvoiceItem>
  
  calculated_billing_schedule: Array<PolicyBillingAmountDate>
  remaining_billing_schedule: Array<PolicyBillingAmountDate>
  upcoming_invoice_details: PolicyBillingAmountDate

  policy_items?: Array<PolicyItem>
}